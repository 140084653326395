import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { CapitalLetter } from 'app/components/Icons'
import { Media } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  description?: string
  items: ItemProps[]
  title?: string
}

export const WellnessSlider = memo(function WellnessSlider({
  description,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx]?.distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1000,
    },
    drag: false,
    loop: false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      items.length > 1 ? setDetails(s.track.details) : null
    },
  })

  const [thumbsSliderRef, thumbsRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1500,
    },
    drag: false,
    loop: false,
    slides: {
      origin: 'center',
      perView: 2.05,
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    setLoaded(newLoaded)
  }, [currentSlide, galleryRef, sliderRef, thumbsRef, thumbsSliderRef])

  return (
    <>
      <TextContainer>
        <CapitalLetter />
        {title ? (
          <FadeInUp>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </TextContainer>

      <Container>
        <Media greaterThanOrEqual="desktopSmall">
          <Carousel ref={sliderRef}>
            {uniq(items).map((item, index) =>
              item.image ? (
                <Slide key={index} className="keen-slider__slide">
                  <Inner style={positionStyle(index)}>
                    <Image
                      alt={loaded[index] || nextLoaded[index] ? item.title : ''}
                      media="(min-width: 2800px)"
                      {...(loaded[index] || nextLoaded[index]
                        ? item.image
                        : null)}
                    />
                    <Spinner />
                  </Inner>
                </Slide>
              ) : null,
            )}
          </Carousel>

          <Thumbs ref={thumbsSliderRef}>
            {uniq(items).map((item, index) =>
              item.title ? (
                <Item
                  key={index}
                  className={`keen-slider__slide${
                    currentSlide === index ? ' active' : ''
                  }`}
                  {...item}
                  onClick={() => {
                    galleryRef.current?.moveToIdx(index)
                    thumbsRef.current?.moveToIdx(index)
                    setCurrentSlide(index)
                  }}
                />
              ) : null,
            )}
          </Thumbs>
        </Media>

        <Media lessThan="desktopSmall">
          {uniq(items).map((item, index) => (
            <Carousel key={index}>
              <Slide>
                <Image {...item.image} />
                <Item className="active" {...item} />
              </Slide>
            </Carousel>
          ))}
        </Media>
      </Container>

      <Separator />
    </>
  )
})

const TextContainer = styled.div`
  position: relative;
  padding: 0 12.5vw;
  margin-bottom: 6.25vw;

  @media (max-width: 1439px) {
    padding: 0 1.5625rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 4.375rem;
  }

  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: auto;
    height: 25vw;

    @media (max-width: 1199px) {
      height: 13.9375rem;
    }

    path {
      fill: #67809d;
    }
  }
`

const Title = styled.h2`
  position: relative;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5.31vw;
  line-height: 1.058;
  text-align: center;
  z-index: 1;

  @media (max-width: 1199px) {
    font-size: 3.4375rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.variants.neutralLight3};
    font-weight: unset;
    font-size: 3.43vw;

    @media (max-width: 1199px) {
      font-size: 1.5625rem;
    }
  }
`

const Description = styled.div`
  position: relative;
  max-width: 35vw;
  margin: 2.5vw auto 0;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;
  z-index: 1;

  @media (max-width: 1600px) {
    max-width: 35rem;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    font-size: 0.8125rem;
  }
`

const Container = styled.section`
  position: relative;
  overflow: hidden;
`

const Carousel = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 56.25vw;
  max-height: 100vh;
  min-height: 43.75rem;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(20, 21, 23, 0.15) 0%, #141517 100%);
    opacity: 0.4;
    z-index: 2;

    @media (max-width: 1199px) {
      z-index: 3;
    }
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;

  @media (max-width: 1199px) {
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Thumbs = styled.div`
  position: absolute;
  bottom: 4.37vw;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  width: 100%;
  max-width: 70vw;
  outline: none;
  overflow: visible;
  z-index: 2;
`

const Separator = styled.div`
  width: 100%;
  height: 7.5vw;

  @media (max-width: 1199px) {
    height: 5rem;
  }
`
